import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'uoz-test6',
  templateUrl: './test6.component.html',
  styleUrls: ['./test6.component.css']
})
export class Test6Component implements OnInit {

  constructor(private questionService: QuestionsService) { }

  ngOnInit() {
    this.questionService.getFileTypeHtml().subscribe( response => console.log(response));
  }

}
