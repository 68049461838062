import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'uoz-test9',
  templateUrl: './test9.component.html',
  styleUrls: ['./test9.component.css']
})
export class Test9Component implements OnInit {

  constructor(private serviceQuestions: QuestionsService) { }

  ngOnInit() {
    this.serviceQuestions.patchAnotherExample(5).subscribe(results => console.log(results));
  }

}
