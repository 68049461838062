import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'uoz-test5',
  templateUrl: './test5.component.html',
  styleUrls: ['./test5.component.css']
})
export class Test5Component implements OnInit {
  constructor(private questionService: QuestionsService) { }

  ngOnInit() {
    this.questionService.getRegularResponse()
      .subscribe(response => {
        console.log(response);
      });
  }

}
