import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'uoz-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  periodOptions = ['All', 'Today', 'Last Month', 'Last 6 Months', 'Last Year'];
  surveyOption = ['Initial Survey', 'First Draft Survey', 'Survey 1', 'Baseline Questionnaire', 'Follow-up 12 Questionnaire'];
  breakpoint: any;
 breakpointLine;
 breakpoi
  constructor() { }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 1320 ) ? 6: 12;
  }


  onResize(event) {
    if(event.target.innerWidth <= 1320 && event.target.innerWidth > 810){
      this.breakpoint = 6;
    } else {
      this.breakpoint = 12
    }

    if(event.target.innerWidth <= 810){
      this.breakpoint = 3;

    }

    console.log(this.breakpoint)
  }

}
