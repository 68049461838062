import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'uoz-counter-card',
  templateUrl: './counter-card.component.html',
  styleUrls: ['./counter-card.component.scss']
})
export class CounterCardComponent implements OnInit {

  @Input() options: string[];
  @Input() title: string;
  @Input() color: string;
  @Input() descr: string;
  selectedOption;

  constructor() { }

  ngOnInit() {
    this.selectedOption = this.options[0];
  }

}
