import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'uoz-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit{
  @ViewChild('drawer') drawer: ElementRef;
  isSidenavOpen = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  breakpoint;

 ngOnInit(){

 }


  constructor(private breakpointObserver: BreakpointObserver) {

  }
  onToggle() {
    this.isSidenavOpen = !this.isSidenavOpen;
    console.log(this.drawer['_elementRef']['nativeElement']['classList'])
  }
}
