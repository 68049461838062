import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'uoz-table',
  templateUrl: './uoz-table.component.html',
  styleUrls: ['./uoz-table.component.scss']
})
export class UozTableComponent implements OnInit {

  @Input() displayedColumns: string[] = [];
  @Input() dataSource: string[] = [];
  constructor() { }

  ngOnInit() {
  }

  onRowClicked(row) {
    console.log(row);
  }

  trackPost(index, item) { return index; }

}
