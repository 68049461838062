import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'uoz-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  selectedFile = null;
  constructor(private httpClient: HttpClient) { }

  ngOnInit() {

  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }


    onUpload() {
      const formData = new FormData();
      if (!this.selectedFile ) {
        console.log('Enter a file');
        return;
      }
      formData.append('files', this.selectedFile, this.selectedFile.name);
      formData.append('ref', 'posts');
      formData.append('refId', '5c6a869faf672e038f9bee03');
      formData.append('field', 'files');
      const params = new HttpParams();

      const options = {
          params,
          reportProgress: true,
      };


      const req = new HttpRequest('POST', 'upload', formData, options);
      this.httpClient.request(req).subscribe(console.log);
  }



}
