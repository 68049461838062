import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';
import { FirstApiResultsDto } from '../../interfaces/app.interfaces';

@Component({
  selector: 'uoz-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css']
})
export class QuestionnaireComponent implements OnInit {
 response: FirstApiResultsDto;
  constructor(private questionsService: QuestionsService,
            ) { }

  ngOnInit() {
    this.questionsService.getAnotherRead()
    .subscribe(
      results => {
        this.response = results;
        console.log(this.response);
      }
    );
  }


}
