import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'uoz-test3',
  templateUrl: './test3.component.html',
  styleUrls: ['./test3.component.css']
})
export class Test3Component implements OnInit {
  myError;
  snachBarArguments = {verticalPosition: 'top', duration: 3000};
              test: any;
  constructor(private questionsService: QuestionsService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
   this.questionsService.getCustonError()
        .subscribe(
        result => {},
         error => {
           this.myError = error.message;
           this.snackBar.open(error.message, 'ERROR', {verticalPosition: 'top', duration: 3000});
        });
  }



}
