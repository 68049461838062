import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AuthenticationGuard} from './core';
import { DashboardComponent } from './dashboard/dashboard.component';

export const routes: Routes = [
  {
    path: '', component: HomeComponent, canActivate: [AuthenticationGuard], data: {
      roles: ['admin', 'authenticated']
    }
  },
  {
    path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard], data: {
      roles: ['admin', 'authenticated']
    }
  },
  {
    path: 'dashboard', component: DashboardComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}
