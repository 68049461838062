import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { FirstApiResultsDto, SecondApiResultsDto } from '../interfaces/app.interfaces';
import { QuestionListPutDto } from '../interfaces/dtos/put/questionListPutDto';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService extends DataService {

  constructor(httpClient: HttpClient) {
    super('', httpClient); // TEST 3
   }

   getCustonError() {
     return this.httpClient.get('statusCodes/customError');
   }

   getBoomError() {// Test 4
    return this.httpClient.get('statusCodes/boomError');
  }

  getRegularResponse() {// Test 5
    return this.httpClient.get('statusCodes/regularResponse');
  }

   getFileTypeJson() {// TEST 1
     return this.httpClient.get<SecondApiResultsDto[]>('fileTypes/json');
   }

   getFileTypeText() {// Test 2
     return this.httpClient.get('fileTypes/text', {responseType: 'text'});
   }

   getFileTypeHtml() {// Test 6
     return this.httpClient.get('fileTypes/html');
   }

   getSimpleExa() {/// HOME
     return this.httpClient.get<SecondApiResultsDto[]>('simpleExample');
   }

   getAnotherRead() {// Questionnaire
     return this.httpClient.get<FirstApiResultsDto>('anotherExample/read');
   }

   putAnotherExample(id) {
     return this.httpClient.put('notherExample/update/', id);
   }

   patchAnotherExample(id) {
     return this.httpClient.patch('notherExample/update/', id);
   }

   deleteAnotherExample(id) {
    return this.httpClient.delete('notherExample/update/', id);
  }

  putSorting(putEvent) {
    if (putEvent.direction === '') {
      putEvent.direction = 'default';
    }
    this.httpClient.put<QuestionListPutDto>('anotherExample/update/', putEvent.direction);
  }

  pagginationPut(putEvent) {
    console.log(putEvent);
    console.log(putEvent.pageSize, putEvent.pageIndex);
    this.httpClient.put<QuestionListPutDto>('anotherExample/update/', {total: putEvent.pageSize, pageIndex: putEvent.pageIndexEvent + 1});
  }

}

