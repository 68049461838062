import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { RoutingModule } from './routing.module';
import { QuestionsComponent } from './questions/questions.component';
import { HttpClientModule } from '@angular/common/http';
import { QuestionnaireComponent } from './questions/questionnaire/questionnaire.component';
import { Test2Component } from './questions/test2/test2.component';
import { Test3Component } from './questions/test3/test3.component';
import { Test4Component } from './questions/test4/test4.component';
import { Test5Component } from './questions/test5/test5.component';
import { Test6Component } from './questions/test6/test6.component';
import { Test7Component } from './questions/test7/test7.component';
import { Test8Component } from './questions/test8/test8.component';
import { Test9Component } from './questions/test9/test9.component';
import { Test10Component } from './questions/test10/test10.component';
import { FormsModule } from '@angular/forms';
import { DataService } from './services/data.service';
import { QuestionsService } from './services/questions.service';
import { SharedModule } from './shared';
import { LoginModule } from './login/login.module';
import { UploadFileComponent } from './questions/upload-file/upload-file.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxUploaderComponent } from './shared/ngx-uploader/ngx-uploader.component';
import { DownloadTableComponent } from './questions/download-table/download-table.component';
import {HttpModule} from '@angular/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CounterCardComponent } from './counter-card/counter-card.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    QuestionsComponent,
    QuestionnaireComponent,
    Test2Component,
    Test3Component,
    Test4Component,
    Test5Component,
    Test6Component,
    Test7Component,
    Test8Component,
    Test9Component,
    Test10Component,
    UploadFileComponent,
    NgxUploaderComponent,
    DownloadTableComponent,
    DashboardComponent,
    CounterCardComponent,
    DoughnutChartComponent,
    LineChartComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    RoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    LoginModule,
    SharedModule,
    NgxUploaderModule,
  ],
  providers: [
    DataService,
    QuestionsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
