import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private url: string, protected httpClient: HttpClient ) { }

  getAll() {
    return this.httpClient.get(this.url);
  }

  create(resource) {
    return this.httpClient.post(this.url, JSON.stringify(resource));

  }

}
