import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import {MaterialModule} from '../material.module';
import { UozTableComponent } from './uoz-table/uoz-table.component';
import {KeysPipe} from './keys.pipe';
import { CoreModule } from '../core';

@NgModule({
  imports: [MaterialModule, CommonModule, CoreModule,
   ],
  declarations: [LoaderComponent, UozTableComponent, KeysPipe,
    ],
  exports: [LoaderComponent, UozTableComponent, KeysPipe, ]
})
export class SharedModule {}
