import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {User} from '../core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

export interface UserModel {
  id: number;
  username: string;
  email: string;
  role: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getUsers( filter = '', sortOrder = 'asc', pageNumber = 1, pageSize = 20): Observable<UserModel[]> {
    return this.httpClient.get<User[]>('users', {
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(map(res =>
      res.map( (user, index: number) => {
        return {
          id: user.id,
          email: user.email,
          username: user.username,
          role: user.role.name,
        };
      })
    ));
  }
}
