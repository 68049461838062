import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
@Component({
  selector: 'uoz-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {
 options = ['All', 'Today', 'Last Month', 'Last 6 Month', 'Last Year']
 selectedOption;

  public doughnutChartLabels = ['Web', 'Tablet', 'Mobile'];
  public doughnutChartData = [20, 10, 30];
  public doughnutChartType = 'doughnut';
  public doughnutChartColors: any[] = [{ backgroundColor: ['#3E97E8', '#F44336', '#3F51B5'] }];
  chart: any;

  constructor() {
  }

  ngOnInit() {
    this.selectedOption = this.options[0];


    this.chart = new Chart('canvas', {
      type: 'doughnut',

      data: {

        labels: ['Web', 'Mobile', 'Tablet'],

        datasets: [
          {
            data: this.doughnutChartData,
            backgroundColor: ['#3E97E8', '#F44336', '#3F51B5'],
            fill: true,

          },

        ]
      },
      options: {

        maintainAspectRatio: false,
        responsive: true,
        cutoutPercentage: 75,

        layout: {
          padding: {
            top: 30,
            bottom: 70,
            right: 0,

          },
        },
        legend: {
          display: true,
          position: 'left',
          labels: {
            boxWidth: 20,
            padding: 50
          }

        },
        scales: {

          xAxes: [{
            display: false

          }],
          yAxes: [{
            scaleLabel: {
              display: true

            },
            display: false

          }]
        }
      }
    });
  }

}
