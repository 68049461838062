import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'uoz-test10',
  templateUrl: './test10.component.html',
  styleUrls: ['./test10.component.css']
})
export class Test10Component implements OnInit {

  constructor(private serviceQuestions: QuestionsService) { }

  ngOnInit() {
    this.serviceQuestions.deleteAnotherExample(5).subscribe( results => console.log(results));
  }

}
