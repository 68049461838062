import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';

@Component({
  selector: 'uoz-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  chart: any;
  constructor() { }

  ngOnInit() {
    this.chart = new Chart('canvas2', {
      type: 'line',

      options: {
        scales: {
          yAxes: [{
            ticks: {
              // autoSkip: true,
              maxTicksLimit: 7
            }

          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {

          display: true,
          position: 'top',

          labels: {
            boxWidth: 16,
            padding: 30,
            usePointStyle: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            borderJoinStyle: 'round'
          }
        }
      },
      data: {

        labels: ['12am', '2am', '4am', '6am', '8am', '10am', '12pm', '2pm', '4pm', '6pm', '8pm', '10pm'],
        datasets: [
          {
            label: 'Surveys',
            data: [450, 300, 510, 770, 880, 550, 490, 630, 510, 370, 580, 750],
            backgroundColor: ['#3F51B5'],
            fill: true,
            lineTension: 0.3,
            borderColor: '#3E97E8',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointRadius: 3,
            pointBackgroundColor: '#3F51B5',
            pointBorderColor: '#fff',

          },
          {
            label: 'Users registered',


            data: [3500, 2800, 3900, 2950, 1540, 3050, 2330, 1170, 1950, 4000, 4700, 3000,],
            backgroundColor: ['#3E97E8'],
            fill: true,

            lineTension: 0.3,
            borderColor: '#3E97E8',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointRadius: 3,
            pointBackgroundColor: '#3E97E8',
            pointBorderColor: '#fff',

          },


        ],

      },



    });
  }

}
