import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  url = environment.apiUrl;
  constructor(private http: HttpClient) { }
  downloadFile(element) {
    return this.http.get(this.url + element.url, {
        responseType: 'blob',

      }).pipe(map(res => {
        return {
          filename: element.name,
          data: res
        };
      }))
      .subscribe(res => {
        console.log('start download:', res);
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }
}
