import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'uoz-test8',
  templateUrl: './test8.component.html',
  styleUrls: ['./test8.component.css']
})
export class Test8Component implements OnInit {

  constructor(private serviceQuestions: QuestionsService) { }

  ngOnInit() {
    this.serviceQuestions.putAnotherExample(5).subscribe(response => console.log(response));
  }

}
