import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Component({
  selector: 'uoz-test4',
  templateUrl: './test4.component.html',
  styleUrls: ['./test4.component.css']
})
export class Test4Component implements OnInit {
  errorPosition: MatSnackBarConfig = {verticalPosition: 'top', duration: 3000};

  constructor(private questionsService: QuestionsService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.questionsService.getBoomError()
        .subscribe(
          result => console.log(result),
          error => {
            console.log(error.message);
            this.snackBar.open(error.message, 'ERROR', {verticalPosition: 'top', duration: 3000});
          });

  }

}
