import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'uoz-test2',
  templateUrl: './test2.component.html',
  styleUrls: ['./test2.component.css']
})
export class Test2Component implements OnInit {
  result = '';
  constructor(private questionService: QuestionsService) { }

  ngOnInit() {
    this.questionService.getFileTypeText()
      .subscribe(
        result => {
          this.result = result;
        });
  }

}
