import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {SessionService} from './session.service';

export interface UserRole {
  name: string;
  type: string;
}

export class User {
  id: number;
  username: string;
  email: string;
  role: UserRole;
}

export class UserResultDto {
  user: User;
  jwt: string;
}


export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

export interface UserPostDto {
  identifier: string;
  password: string;
}

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService
  ) {
  }

  login(identifier: string, password: string) {
    return this.httpClient.post<UserResultDto>(`auth/local`, { identifier, password, jwtOptions: {expiresIn: 1} })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.jwt) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.sessionService.currentUserSubject.next(user);
        }

        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.sessionService.currentUserSubject.next(null);
  }
}
