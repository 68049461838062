import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { DownloadService } from '../../shared/download.service';
@Component({
  selector: 'uoz-download-table',
  templateUrl: './download-table.component.html',
  styleUrls: ['./download-table.component.scss'],
})
export class DownloadTableComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['action', 'size', 'title', 'orderDate', 'description', ];
  dataSource: MatTableDataSource<object>;
  DATA;
  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions = [1, 2, 5, 10];

  constructor(private downloadService: DownloadService, private httpClient: HttpClient) { }

  ngOnInit() {
    this.httpClient.get('posts').subscribe(
      results => {
        this.DATA = results[0].files;
        console.log(this.DATA);
        this.loadData(0, this.pageSize);
        this.dataSource.sort = this.sort;
      }
    );
  }

  loadData(pageIndex, pageSize) {
    this.dataSource = new MatTableDataSource<object>(this.DATA.slice(pageIndex, pageIndex + pageSize));
  }

  onPageChange(e) {
    const previousPageIndex = e.previousPageIndex;
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    const length = e.length;
    this.loadData(this.pageIndex, this.pageSize);
  }


  download(element) {
    this.downloadService.downloadFile(element);
  }

}
