import { Component, OnInit, EventEmitter } from '@angular/core';
import { UploadFile, UploadInput, UploaderOptions, humanizeBytes, UploadOutput, UploadStatus } from 'ngx-uploader';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'uoz-ngx-uploader',
  templateUrl: './ngx-uploader.component.html',
  styleUrls: ['./ngx-uploader.component.scss']
})
export class NgxUploaderComponent implements OnInit {
 jwt = (JSON.parse(localStorage.getItem('currentUser')));
  url = 'upload';
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  options: UploaderOptions;

  constructor() {
    this.options = { concurrency: 1, maxUploads: 3 };
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {

  }
  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: environment.apiUrl + this.url,
        method: 'POST',
        fieldName: 'files',
        data: {
          ref: 'posts',
        refId: '5c6a869faf672e038f9bee03',
        field: 'files'
        },
        headers:  { Authorization: 'Bearer ' + this.jwt.jwt }
      };
      console.log();
      this.uploadInput.emit(event);

    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'cancelled' || output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
      console.log(output.file.name + ' rejected');
    }

    this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: environment.apiUrl + this.url,
      method: 'POST',
      data: {
        ref: 'posts',
        refId: '5c6a869faf672e038f9bee03',
        field: 'files'
      }

    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }
}
