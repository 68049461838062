import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User, UserResultDto} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public currentUserSubject = new BehaviorSubject<UserResultDto>(JSON.parse(localStorage.getItem('currentUser')));
  public currentUser: Observable<UserResultDto> = this.currentUserSubject.asObservable();



  public get currentUserValue(): UserResultDto {
    return this.currentUserSubject.value;
  }

}
