import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions.service';
import { SecondApiResultsDto } from '../../interfaces/app.interfaces';

@Component({
  selector: 'uoz-test7',
  templateUrl: './test7.component.html',
  styleUrls: ['./test7.component.css']
})
export class Test7Component implements OnInit {
response: SecondApiResultsDto[];
  constructor(private serviceQuestions: QuestionsService) { }

  ngOnInit() {
    this.serviceQuestions.getSimpleExa()
          .subscribe( (result: SecondApiResultsDto[]) => {
            console.log(result);
            this.response = result;
          });
  }

}
